.product_hero_img_container {
  position: relative;
  background-color: rgb(243, 243, 243);
  width: 100%;
  background-image: url("../../assets/product_hero_img.jpg");
  min-height: 70vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

@media (max-width: 1000px) {
  .product_hero_img_container {
    background-attachment: scroll;
  }
}
