.price_hero {
  margin-bottom: 10rem;
}
.price_hero p {
  text-align: center !important;
}

.pricing_outr_container {
  background-color: #f6f4f2;
  padding: 2rem;
}
.pricing_container {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: auto;
  margin: 2rem auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.pricing_img {
  /* background-color: grey; */
  height: 100%;
  width: 40%;
}

.pricing_img img {
  width: 100%;
  height: 45rem;
  object-fit: cover;
  object-position: center;
}

.pricing_txt {
  /* background-color: rgb(226, 204, 208); */
  width: 60%;
  padding: 1rem 8rem;
}

.pricing_txt p span {
  font-weight: bold;
}

/* .pricing_container ul {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
} */

/* Align the prices */
.pricing_container ul {
  list-style: none; /* Remove default bullet points */
  padding: 0;
  margin: 0;
}

.pricing_container ul li {
  display: flex;
  justify-content: space-between; /* Align the text and price neatly */
  padding: 10px 0; /* Add vertical spacing */
  border-bottom: 1px solid #eee; /* Optional divider for clarity */
}

.pricing_container ul li:last-child {
  border-bottom: none;
}
.pricing_container ul li p:last-child {
  font-weight: 550;
}

.pricing_container ul li p {
  margin: 0; /* Remove default paragraph margins */
}
/* #################### */

.pricing_container p {
  margin-bottom: 5px;
  padding-left: 5px;
}
.pricing_container span:nth-of-type(1) p {
  font-style: italic;
  margin-top: 1rem;
  font-weight: 600;
}
.pricing_container span:nth-of-type(2) p {
  font-style: italic;
}
.pricing_txt h2 {
  margin-bottom: 20px;
}
.pricing_txt p {
  padding: 0;
  margin: 0;
}

/* ############## */
.pricing_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.pricing_grid p {
  padding: 0;
  margin: 0;
}
.pricing_grid h3 {
  font-family: "Red Hat Text", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.125rem;
  margin: 0; /* Remove default margin */
}

@media (max-width: 1600px) {
  .pricing_container {
    width: 98%;
  }

  .pricing_txt {
    padding: 2rem 5rem;
  }
}

@media (max-width: 1000px) {
  .pricing_container {
    display: block;
  }
  .pricing_img {
    width: 100%;
    height: 25rem;
  }
  .pricing_img img {
    height: 100%;
  }
  .pricing_txt {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .pricing_outr_container {
    padding: 0rem;
  }
  .pricing_container {
    width: 100%;
  }
  .pricing_txt {
    padding: 2rem 2rem;
  }
}
@media (max-width: 600px) {
  .pricing_container ul li {
    display: block; /* Stack items vertically */
    text-align: left; /* Align text to the left */
  }

  .pricing_container ul li p:last-child {
    margin-top: 5px;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .pricing_grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
