.testimonial_title {
  /* background-color: pink; */
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 6rem;
}

.testimonial_title p {
  margin-top: 1rem;
}

.otr_testimonial_cont {
  background-color: #f6f4f2;
  padding: 3rem;
  margin-top: 2rem;
}

.inner_testimonial_cont {
  height: 60vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial_txt_cont {
  width: 60%;
  height: 100%;
  /* background-color: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial_txt {
  width: 70%;
}
.testimonial_txt h3 {
  font-family: "Red Hat Text", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.125rem;
  font-style: italic;
  margin-top: 0.5rem;
}
.testimonial_txt p {
  margin-top: 1rem;
}

/* ############## */

.testimonial_img_cont {
  /* background-color: rgb(224, 197, 201); */
  width: 40%;
  height: 100%;
}
.testimonial_img {
  width: 50%;
  height: 100%;
  margin: auto;
  /* background-color: grey; */
}
.testimonial_img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
/* ################ */
.testimonial_no_img {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* Adjust min size to fit your design */
  gap: 2rem;
  width: 90%; /* Adjust if needed */
  margin: 2rem auto;
}

.testimonial_no_img_item {
  background-color: #f6f4f2; /* Example background */
  padding: 1rem;
  min-height: 250px; /* Fixed height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box; /* Ensure padding and border are included in width/height calculations */
}

.testimonial_no_img_text {
  background-color: white;
  height: 100%;
  width: 100%;
  padding: 2rem;
}
.quote-icon {
  width: 50px;
  height: 50px;
  fill: #967072;
}
@media (max-width: 1400px) {
  .testimonial_title {
    padding: 0 2rem;
  }
  .testimonial_img {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .otr_testimonial_cont {
    padding: 1rem;
  }
  .inner_testimonial_cont {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 20px 0;
  }
  .testimonial_txt_cont {
    height: auto;
    width: 100%;
  }
  .testimonial_img_cont {
    width: 100%;
  }
  .testimonial_img {
    width: 80%;
    height: 400px;
  }
  .testimonial_txt {
    width: 80%;
  }
  .testimonial_no_img_text {
    width: 100%;
  }
}
