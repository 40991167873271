.nopage_img_container {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../assets/product_hero_img.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nopage_text {
  width: 100%;
  text-align: center;
  color: white;
}

.nopage_text h1 {
  font-size: 5rem;
}
.nopage_text h3 {
  font-size: 3rem;
}

.nopage_text p {
  font-size: 1.5rem;
}

.nopage_text button {
  padding: 10px 20px;
  background-color: #f5dad1;
}
.nopage_text button:hover {
  background-color: #f7e3dc;
}
