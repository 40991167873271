.product-page .product_hero_container {
  position: relative;
  background-color: rgb(243, 243, 243); /* Background color */
  width: 100%;
  background-image: url("../../assets/ladoo_order_bg_img.jpg");
  min-height: 80vh; /* Height of the section */
  background-attachment: fixed; /* Keeps the image fixed when scrolling */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Ensures the image doesn't repeat */
  background-size: cover; /* Makes the image cover the entire section */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the content vertically */
  align-items: center; /* Centers the content horizontally */
  margin-top: 0;
}

.product-page .product_hero_container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay for text visibility */
  z-index: 1; /* Ensures the overlay stays under the text */
}

.product-page .product_hero_container > * {
  position: relative;
  z-index: 2; /* Brings text content above the overlay */
}

.product-page .product_hero_container h1 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.product-page .product_hero_container p {
  color: white;
  text-align: center;
  max-width: 600px;
  line-height: 1.5;
}
/* ################################# */

.order_instructions {
  width: 90%;
  margin: 100px auto 0 auto;
  padding-left: 1rem;
}
.order_instructions ul {
  padding-left: 0rem;
}
.order_instructions p {
  margin: 0;
}

.product-container {
  width: 90%;
  height: fit-content;
  margin: 0 auto 100px auto;
  text-align: center;
  display: flex;
}
.ladoo_choice_container {
  width: 50%;
}

.ladoos-count-selection {
  text-align: left;
}

.ladoos-button {
  width: 130px;
  height: 45px;
  cursor: pointer;
  border: 2px solid transparent;
  background-color: #f8f8f8;
  transition: border 0.3s ease, background-color 0.3s ease;
  margin: 5px;
  color: black;
}

.ladoos-button.selected {
  border: 2px solid #68363c69;
}

.selection {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladoo_selection_txt_container {
  width: 100%;
  text-align: left;
  padding-left: 1rem;
  text-wrap: balance;
}
.ladoo_selection_txt_container h3 {
  margin-top: 20px;
}

.flavour-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 150px));
  grid-column-gap: 0rem;
  grid-row-gap: 0rem;
  padding: 0.5rem;
  justify-content: left;
  gap: 0;
  margin: 10px 0;
  width: 100%;
}

.flavour-image {
  width: 70px;
  height: 70px;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.3s ease;
}

.flavour-image.selected {
  border: 2px solid #68363c69;
}

.topping-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  grid-gap: 0.5rem;
  padding: 0.5rem;
  width: 100%;
}

.topping-button {
  width: 130px;
  height: 50px;
  cursor: pointer;
  border: 2px solid transparent;
  background-color: #f8f8f8;
  transition: border 0.3s ease, background-color 0.3s ease;
  color: black;
}

.topping-button.selected {
  border: 2px solid #68363c69;
}

.ladoo-count-selection {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
}
/* ################ */
.ladoos-button:disabled {
  opacity: 0.6;
  filter: grayscale(100%);
  pointer-events: none; /* Disable interaction */
  user-select: none;
}
.ladoos-button:disabled:hover {
  filter: grayscale(100%); /* Avoid hover effects */
}
/* ################### */

.add_btn_container {
  display: flex;
  justify-content: center;
}

.add_btn_sub_cont {
  width: 100%;
  text-align: left;
  /* padding-left: 1rem; */
}

.ladoo_btn {
  background-color: #f5dad1;
  width: 150px;
  height: 50px;
  color: black;
}

.preview_container {
  width: 50%;
  position: relative;
  background-color: #f8f8f8;
}
.ladoo_box_sticky {
  position: sticky;
  top: 0;
}

.preview_text {
  text-align: left;
  text-wrap: balance;
  padding: 2rem;
}
.preview_text span {
  font-weight: 600;
  font-style: italic;
}

.cardboard-container {
  width: fit-content;
  height: fit-content;
  background-size: cover;
  background-position: center;
  margin: auto;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* background-color: rgb(228, 227, 227); */
  background-color: #68363c69;
}

.ladoo-grid {
  display: grid;
  gap: 10px;
  width: 100%;
  height: 100%;
}
.ladoo-grid.ladoos-4 {
  grid-template-columns: repeat(2, 1fr);
}

.ladoo-grid.ladoos-6 {
  grid-template-columns: repeat(3, 1fr);
}

.ladoo-grid.ladoos-9 {
  grid-template-columns: repeat(3, 1fr);
}

.ladoo-grid.ladoos-12 {
  grid-template-columns: repeat(4, 1fr);
}

.ladoo-box {
  width: 150px;
  height: 150px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  text-align: center;
  font-size: 12px;
  color: #333;
  border-radius: 30px;
  cursor: pointer;
  transition: border 0.3s ease;
}

.ladoo-box img {
  max-width: 80%;
  max-height: 60%;
  margin-bottom: 5px;
}
.ladoo-box p {
  margin: 2px;
}

.refresh-button {
  border-radius: 20px;
}

@media (max-width: 1500px) {
  .product-container {
    width: 90%;
    height: fit-content;
    margin: 0px auto 100px auto;
    text-align: center;
    flex-direction: column;
  }
  .ladoo_choice_container {
    width: 100%;
  }
  .preview_container {
    width: 100%;
    margin-top: 2rem;
    padding-top: 2rem;
    background-color: white;
  }
}
@media (max-width: 1200px) {
  .product-container {
    width: 100%;
    padding: 0 2rem;
  }
  .preview_text {
    padding: 2rem 0;
  }
}

@media (max-width: 1000px) {
  .product-page .product_hero_container {
    background-attachment: scroll;
  }
  .ladoo_hero_outer_container {
    display: block;
  }

  .ladoo_hero_img {
    width: 100%;
    min-height: 40vh;
  }

  .ladoo_txt_cont {
    width: 100%;
    padding: 3rem 0;
  }

  .preview_container {
    display: block;
  }

  /* .preview_text {
    padding-left: 0;
    margin-top: 2rem;
  } */
}

@media (max-width: 768px) {
  .ladoos-count-selection {
    text-align: center;
    margin-top: 2rem;
  }

  .ladoos-button {
    display: inline-block;
  }

  .ladoo-box {
    width: 100px;
    height: 100px;
    margin: auto;
  }

  .preview_container {
    padding: 0 0rem;
  }

  .cardboard-container {
    padding: 10px;
    margin: auto;
  }

  .ladoo-grid {
    display: grid;
    gap: 10px;
    width: 100%;
    height: 100%;
  }
  .ladoo_selection_txt_container {
    padding-left: 0rem;
  }
  .ladoo_selection_txt_container h3 {
    text-align: center;
  }

  .add_btn_sub_cont {
    padding-left: 0rem;
  }

  .flavour-options {
    grid-template-columns: repeat(auto-fit, minmax(50px, 80px));
    justify-content: center;
  }

  .topping-options {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 0rem;
    width: 100%;
  }

  .flavour-image {
    width: 70px;
    height: 70px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: border 0.3s ease;
  }

  .add_btn_sub_cont {
    width: 100%;
    text-align: center;
  }
  .ladoos-button {
    text-align: center;
  }
  .ladoo-grid.ladoos-12 {
    grid-template-columns: repeat(3, 1fr);
  }
  .ladoo-box {
    width: 150px;
    height: 150px;
    margin: auto;
  }
  .ladoo-box img {
    max-width: 70px;
    max-height: 70px;
    margin-bottom: 1px;
  }
}
@media (max-width: 550px) {
  .ladoo-box img {
    max-width: 70px;
    max-height: 70px;
    margin-bottom: 1px;
  }
  .ladoo-box {
    width: 120px;
    height: 150px;
    margin: auto;
  }
}

@media (max-width: 450px) {
  .ladoo-grid.ladoos-9 {
    grid-template-columns: repeat(2, 1fr);
  }
  .ladoo-grid.ladoos-6 {
    grid-template-columns: repeat(2, 1fr);
  }
  .ladoo-grid.ladoos-12 {
    grid-template-columns: repeat(2, 1fr);
  }
}
