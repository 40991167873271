.scroll-to-top-button {
  position: fixed;
  bottom: 90px;
  right: 20px;
  background-color: #6a363b;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 1000; /* Ensure the button stays above all content */
  transition: opacity 0.3s ease; /* Fade in/out transition */
}

.scroll-to-top-button:hover {
  opacity: 0.8; /* Reduce opacity on hover */
}

#toTopBtn {
  display: none;
}

@media (max-width: 1000px) {
  #toTopBtn {
    display: block;
  }
}
