/* src/components/Footer.css */

.footer {
  padding: 50px 0;
  border-top: 1px solid #e9ecef;
  margin-top: 15rem;
}

.logo-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo-container img {
  width: 200px;
  margin: 1rem;
}

.footer-top {
  padding: 10px 0;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: center;
}

.footer-content {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}

.footer-info {
  display: flex;
  justify-content: center;
  gap: 20px; /* Space between copyright and terms */
  margin-bottom: 20px;
}

.footer-info p,
.footer-info a {
  margin: 0;
  font-size: 14px;
}

.footer-info a {
  text-decoration: none;
  color: #333;
}

.footer-info a:hover {
  text-decoration: underline;
}

.footer-links {
  margin-bottom: 20px;
}

.footer-links a {
  margin: 0 15px;
  text-decoration: none;
  color: #333;
  font-size: 14px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-social {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.footer-social a {
  margin: 0 10px;
  color: #333;
  font-size: 20px;
  text-decoration: none;
}

.footer-social a:hover {
  color: #007bff; /* Change to your preferred color */
}

@media (max-width: 500px) {
  .footer {
    margin-bottom: 5rem;
  }
  .logo-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .footer-top {
    text-wrap: balance;
  }
}
