.about_hero_cont {
  width: 100%;
  height: calc(100vh);
  display: flex;
  justify-content: center;
  align-items: center;
}
.about_img {
  background-color: rgb(241, 233, 223);
  height: 100%;
  width: 40%;
}

.about_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 20%;
}

.about_txt {
  background-color: #fcf8f6;
  height: 100%;
  width: 60%;
  padding: 0 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/* ####################### */

.about_subtxt {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 2.5rem;
}

.about_subtxt p {
  position: relative;
  margin: 0;
  font-style: italic;
  font-size: 1.25rem;
}
svg {
  display: block;
}

/* ###################### */

.about_content_cont {
  width: 80%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10rem auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.about_content_img {
  background-color: rgb(204, 194, 196);
}
.about_content_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.about_content_txt {
  background-color: #ffffff;
}

.about_content_txt p {
  margin-top: 1rem;
}

.about_content_btn {
  background-color: #f5dad1;
  color: black;
  width: 150px;
  height: 50px;
  margin-top: 0.5rem;
  transition: 0.1s ease-in;
}

@media (max-width: 1700px) {
  .about_txt {
    padding: 0 3rem;
  }
}
@media (max-width: 1200px) {
  /* .about_hero_cont {
  } */
  .about_content_cont {
    margin: 2rem auto;
    width: 100%;
    height: auto;
    display: block;
  }
  .reverse_img {
    display: flex;
    flex-direction: column-reverse;
  }
  .about_content_img {
    height: 50vh;
    width: 100%;
  }
  .about_content_txt {
    width: 100%;
    height: auto;
    padding: 4rem 2rem;
  }
  .about_subtxt p {
    font-size: 1rem;
  }
  .about_content_btn {
    margin: 0;
  }
  #home_hero_contact_btn {
    margin: 0;
  }
}

@media (max-width: 1000px) {
  .about_hero_cont {
    margin: 2rem auto;
    width: 100%;
    height: auto;
    display: block;
  }
  .about_img {
    height: 50vh;
    width: 100%;
  }
  .about_img img {
    object-fit: cover;
    object-position: center 20%;
  }
  .about_txt {
    width: 100%;
    height: auto;
    padding: 4rem 2rem;
  }
}
