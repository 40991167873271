body {
  background-color: white;
}

.hero_outer_container {
  display: flex;
  height: 90vh;
}
.parallax {
  width: 50%;
  height: 100%;
}
.parallax img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center 30%;
}

.title_container {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 6rem;
  text-align: left;
  color: #6a363b;
  background-color: #fcf8f6;
}

.hero_paragraph span {
  font-weight: 600;
}

/* ################# */

.hero_subtitle {
  width: fit-content;
  margin-top: 1rem;
  margin-bottom: 2.5rem;
}
.hero_subtitle p {
  position: relative;
  margin: 0;
  padding: 5px 0;
  font-style: italic;
  font-size: 1.5rem;
  letter-spacing: normal;
}

svg {
  display: block;
}
.second_line svg {
  float: right;
}

/* ############# */

.cake_feature_banner {
  margin: 5rem 0;
  background-color: #fcf8f6;
}

.otr_cont_cake {
  /* background-color: pink; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.cake_txt_cont {
  width: calc(100vw / 20 * 6);
}

/* #cake_btn {
  background-color: #f5dad1;
  color: black;
  width: 150px;
  height: 50px;
  margin-top: 2.125vw;
  transition: 0.1s ease-in;
}

#cake_btn:hover {
  background-color: #f6ece6;
  border-radius: 20px;
} */

.cake_feature_image_container {
  position: relative;
  width: calc(100vw / 20 * 6);
  height: 45vw;
  margin: 0 0 0 calc(100vw / 20 * 3);
}

.cake_feature_image-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.cake_feature_image_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* ######################################################### */

.ladoo_section {
  width: 100%;
  height: 80vh;
  /* background-color: pink; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.main_ladoo_container {
  width: 90%;
  height: 100%;
  background-color: #fcf8f6;
  display: flex;
  box-sizing: border-box;
}

.ladoo_content {
  width: 50%;
  box-sizing: border-box;
}

.outer_ladoo_img_cont {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: pink; */
}
.outer_ladoo_img_cont img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ladoo_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.outer_ladoo_text_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner_ladoo_text_container {
  width: 80%;
}

#subtitle_ladoo {
  font-style: italic;
}

/* #ladoo_portfolio_btn {
  background-color: #f5dad1;
  color: black;
  width: 150px;
  height: 50px;
  margin-top: 2.125vw;
  transition: 0.1s ease-in;
}

#ladoo_portfolio_btn:hover {
  background-color: #f6ece6;
  border-radius: 20px;
} */

/* ########################################### */

.cards_container {
  background-color: #f6f1ec;
  width: 100%;
  display: flex;
  height: 300px;
  margin-top: 8rem;
}

.cards_text_container {
  background-color: #967072;
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cards_text_container h2 {
  color: white;
}

.cards_link_container {
  /* background-color: rgb(235, 235, 235); */
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: left;
}

.crd {
  background-color: #e5e1e1;
  height: 22rem;
  width: 17rem;
  margin-left: 2rem;
  margin-top: 80px;
  display: flex;
  flex-direction: column; /* Set the layout to column */
  justify-content: space-between; /* Space elements evenly */
  align-items: center; /* Center-align elements */
  border: 8px solid white;
  filter: drop-shadow(10px 10px 20px rgba(0, 0, 0, 0.2));
  cursor: pointer;
  transition: filter 0.3s ease, background-color 0.3s ease, transform 0.3s ease;
}

.crd:hover {
  filter: brightness(95%);
  transform: scale(1.02);
}

.crd_flavours {
  background-image: url("../../assets/explore_flavours_img.jpg"); /* Set the background image */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.crd_cupcakes {
  background-image: url("../../assets/explore_cupcakes_img.jpg"); /* Set the background image */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.card_testimonials {
  background-image: url("../../assets/explore_testimonials_img.jpg"); /* Set the background image */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.crd_content {
  width: 100%;
  height: 100%;
}

.crd_label {
  width: 100%;
  /* background-color: #4b4242; */
  background-color: #967072;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0;
}

.crd_label p {
  font-family: "Red Hat Text", sans-serif;
  font-style: normal;
  font-size: 1rem;
  /* background-color: pink; */
  margin: 0;
}

.mission_container {
  /* background-color: pink; */
  width: 90%;
  margin: 250px auto;

  display: flex;
  justify-content: center;
}

.mission_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mission_content {
  /* background-color: #eae2d8; */
  background-color: #fcf8f6;
  width: 50%;
  height: 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mission_image {
  background-color: rgb(180, 179, 179);
}
.mission_text {
  width: 80%;
}
.mission_text p {
  margin: 20px 0;
}

@media (max-width: 1500px) {
  .title_container {
    width: 55%;
    padding: 4rem 3rem;
  }
  .cards_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    padding-bottom: 20px;
    margin-top: 3rem;
  }

  .cards_text_container {
    width: 100%;
    height: 5rem;
    text-align: center;
  }

  .cards_link_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding-right: 1rem;
  }

  .crd {
    min-width: 250px; /* Ensures cards won't shrink too much */
    max-width: 300px; /* Limits the maximum width of the card */
  }
}

@media (max-width: 1200px) {
  .hero_outer_container {
    display: block;
    height: auto;
  }
  .parallax {
    width: 100%;
    /* Parallax-specific properties */
    min-height: 70vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .title_container {
    width: 100%;
    height: auto;
    text-align: center;
    padding: 4rem 2rem;
  }
  .hero_subtitle {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  .cake-ladoo-intro {
    margin-left: auto;
    margin-right: auto;
  }

  .home_hero_btn {
    margin: auto !important;
  }

  #title_new {
    font-size: 2.5rem;
  }
  .hero_para_new {
    font-size: 0.938rem;
  }

  .main_ladoo_container {
    width: 100%;
    padding: 0px;
  }
  .inner_ladoo_text_container {
    width: 100%;
    padding-left: 2rem;
  }
  .ladoo_img_container {
    width: 400px;
  }

  .cake_txt_cont {
    width: 50%;
  }
}

@media (max-width: 1000px) {
  .ladoo_section {
    display: block;
    height: auto;
  }
  .main_ladoo_container {
    display: block;
    height: auto;
  }

  .ladoo_content {
    width: 100%;
  }
  .outer_ladoo_img_cont {
    height: 30rem;
  }
  .outer_ladoo_text_container {
    padding: 2rem;
  }
  .inner_ladoo_text_container {
    width: auto;
    text-align: left;
    padding: 0;
  }

  #ladoo_portfolio_btn {
    display: block;
    margin: 2rem auto 0 auto; /* Centers the button */
    text-align: center;
  }

  .inner_ladoo_text_container br {
    display: none;
  }

  .crd {
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .mission_container {
    width: 100%;
    display: block;
    margin-top: 3rem;
  }
  .mission_content {
    width: 100%;
    height: auto;
    display: block;
  }
  .mission_image {
    height: 30rem;
  }

  .mission_text {
    width: 100%;
    height: auto;
    padding: 2rem;
  }
  .mission_text br {
    display: none;
  }
  #mission_btn {
    display: block;
    margin: 2rem auto 0 auto; /* Centers the button */
    text-align: center;
  }
}
@media (max-width: 768px) {
  .otr_cont_cake {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .cake_txt_cont {
    width: 100%;
    padding: 2rem;
  }
  #cake_btn {
    display: block;
    margin: 2rem auto 0 auto; /* Centers the button */
    text-align: center;
    color: black;
  }

  .cake_feature_image_container {
    position: relative;
    width: 100%;
    height: 30rem;
    margin: 0;
  }

  .cake_feature_image-wrapper1 img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the container */
    object-position: top;
  }

  .cake_feature_image-wrapper2 {
    display: none;
  }
}

@media (max-width: 480px) {
}
