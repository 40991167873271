.product-card {
  position: relative;
  width: 100%;
  height: 100%;
}

.product-image {
  width: 100%;
  height: 420px;
  object-fit: cover;
  object-position: center;
  transition: filter 0.3s ease;
}

.product-image.hovered {
  filter: brightness(50%);
}

.card_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
}

.add-to-cart-btn {
  background-color: #3b3b3b;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.add-to-cart-btn:hover {
  background-color: #4b4b4b;
}

.product_gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 280px));
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  padding: 1rem;
  justify-content: center;
  width: 90%;
}

/* #cake_img {
  width: 100%;
  height: 420px;
  object-fit: cover;
} */

#product_gallery_cont {
  margin: 100px 0;
  display: flex;
  justify-content: center;
}

@media (max-width: 500px) {
  .product_gallery {
    display: flex; /* Switch from grid to flexbox */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center the items horizontally */
    gap: 1rem; /* Space between items */
    width: 100%; /* Make the container take full screen width */
  }

  .product-card {
    width: 100%; /* Ensure each product card takes full width of the screen */
  }

  .product-image {
    display: block;
    width: 100% !important;
    height: 450px !important;
    object-fit: cover !important;
  }
}
