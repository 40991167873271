* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #f6f4f2;
}

.navbar-custom {
  width: 90%;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}

.navbar-custom .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.navbar-nav .nav-link {
  margin-left: 1rem;
}

.icn,
.icn-cart {
  margin-left: 1rem;
}

.cart-item {
  margin: 10px 0px;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid rgb(197, 197, 197);
}
.cart-item button {
  width: 100px;
  height: 30px;
}

.top_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item_info p {
  margin: 0px;
}

.ladoo_list {
  padding-left: 0;
  margin-left: 0;
  list-style: none;
}

.ladoo-detail {
  background-color: rgb(231, 231, 231);
  margin: 3px 0px;
  border-radius: 10px;
  padding: 5px;
}
.ladoo-detail p {
  line-height: normal;
  margin: 0;
}

.modal-body {
  max-height: 60vh;
  overflow-y: auto;
}
/* ################################ */
/* .navbar-toggler {
  border: none !important;
  background: transparent !important;
} */

@media (min-width: 992px) {
  .navbar-custom {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 992px) {
  .navbar-collapse {
    transition: height 0.3s ease !important;
    overflow: hidden !important;
    padding-bottom: 2rem;
  }
  .navbar-custom .nav-link {
    margin-left: 0rem;
    font-size: 16px;
    padding-top: 20px;
  }

  .navbar-custom .dropdown-menu {
    border: none; /* Remove the border */
    box-shadow: none; /* Optional: remove box-shadow if needed */
    text-align: center;
  }
  .navbar-custom .dropdown .dropdown-item {
    padding-bottom: 10px;
    text-decoration: underline;
  }

  .icn,
  .icn-cart {
    font-size: 20px !important;
  }
  .icn-cart {
    margin-left: 10px !important;
  }
}
