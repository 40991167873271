@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&display=swap");

.contact_title_outr {
  /* background-color: pink; */
  margin: 100px 0;
}
.contact_title_sub {
  /* background-color: cyan; */
  text-align: center;
}

.contact_title_sub p {
  margin-top: 20px;
}

.cont_container {
  /* background-color: grey; */
  display: flex;
  justify-content: center;
  gap: 50px;
  text-align: center;
  margin-top: 30px;
}
.contact_item {
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}

.icon_box {
  background-color: white; /* Background color of the box */
  border: 2px solid #8f6769; /* Border around the box */
  border-radius: 50%; /* Rounded corners, making it circular */
  padding: 1rem; /* Space between icon and border */
  margin-bottom: 0.5rem; /* Space between icon box and text */
  display: flex; /* Center the icon inside the box */
  align-items: center; /* Vertically center icon */
  justify-content: center; /* Horizontally center icon */
}

.cont_details h3 {
  font-family: "Red Hat Text", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.125rem;
  margin: 0; /* Remove default margin */
}

.cont_details p {
  letter-spacing: 0.8px;
  line-height: 1.4;
  font-family: "Red Hat Text", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 1rem;
  /* background-color: pink; */
  margin: 0; /* Remove default margin */
}

/* ######################## */
.outer_contact_container {
  background-color: #f6f4f2;
  display: flex;
  width: 100%;
  margin: 2rem 0;
}

.inner_contact_container_text {
  background-color: #8f6769;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white !important;
}
.inner_contact_container_text h2,
.inner_contact_container_text p {
  margin: 0 5rem; /* Add margin to both */
  color: white !important; /* Force white color */
}

.inner_contact_container_form {
  width: 60%;
  display: flex;
  justify-content: center;
}
/* ContactStyle.css */

.display-4 {
  font-size: 2.5rem;
}

.t_border {
  border-top: 2px solid #333;
}

.sec_sp {
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  padding: 0.6rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.form-control:focus {
  border-color: #666;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.1);
}

.inner_contact_container_form .ac_btn {
  background-color: #f5dad1;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 5rem;
  cursor: pointer;
  margin-top: 10px;
}

.inner_contact_container_form .ac_btn:hover {
  background-color: #f5dfd8;
}

textarea.form-control {
  resize: vertical;
}

@media (max-width: 990px) {
  .outer_contact_container {
    display: block;
  }

  .inner_contact_container_text {
    width: 100%;
    padding: 30px 0;
  }
  .inner_contact_container_text p {
    margin: 0 2rem;
  }

  .inner_contact_container_form {
    width: 100%;
  }
  .cont_container {
    gap: 0px;
  }
  .btn_captcha_row {
    display: flex;
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically (if needed) */
    flex-direction: column; /* Stack items vertically */
    text-align: center; /* Ensure text is centered if needed */
    margin: 0 auto; /* Center row in its container */
  }

  .btn_captcha_row .form-group {
    display: flex;
    flex-direction: column; /* Stack ReCAPTCHA and button vertically */
    align-items: center; /* Center items horizontally */
    gap: 1rem; /* Add some space between ReCAPTCHA and button */
  }

  .btn_captcha_row .btn {
    margin-top: 1rem; /* Add space above button */
  }
}
@media (max-width: 480px) {
  .cont_container {
    display: block;
  }
  .contact_item {
    margin: auto;
  }
  .contact_title_sub {
    padding: 0 1rem;
  }
  .inner_contact_container_text h2 {
    margin: 0;
  }
}
