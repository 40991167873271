.flavour_img_container {
  position: relative;
  background-color: rgb(243, 243, 243);
  width: 100%;
  background-image: url("../../assets/flavour_bg_img.jpg");
  min-height: 80vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.flavour_img_container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.flavour_img_container > * {
  position: relative;
  z-index: 2;
}

.flavour_hero_txt {
  text-align: center;
}
.flavour_hero_txt h1 {
  color: white;
  margin-bottom: 30px;
}
.flavour_hero_txt p {
  color: white;
}

.disclaimer-container {
  width: 60%;
  margin: 5rem auto;
}
.disclaimer-container h2 {
  text-align: center;
}

.flavour_container {
  background-color: #ffffff;
  width: 100%;
}

.flavour_content {
  width: 80%;
  height: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.flavour_image {
  background-color: white;
  display: flex; /* Enable Flexbox layout */
  width: 40%; /* Set container width */
  height: 100%;
  overflow: hidden;
  margin: 0 auto; /* Center the container itself if needed */
}

.flavour_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.flavour_txt {
  width: 60%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0 10rem;
}

.flavour_txt h2 {
  margin-bottom: 20px;
}
.flavour_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates 2 equal columns */
  column-gap: 0px; /* Adjust the gap between the columns */
  row-gap: 5px;
}
.flavour_para p {
  padding: 0;
  margin: 0;
}
#topping_title {
  margin-top: 1rem;
}
.fillings_buttercreams_content {
  padding: 0 5rem;
}

.ladoo_flavours {
  flex-direction: column;
}
.ladoo_flavours img {
  height: 50%;
  padding-bottom: 4px;
}

#fillings_buttercreams_link {
  width: fit-content;
  text-decoration: none;
  font-weight: bold;
  color: #6a363b;
}
#fillings_buttercreams_link::after {
  content: " ▼";
  font-size: 12px;
  margin-left: 5px;
  color: inherit;
}
#fillings_buttercreams_link:hover {
  color: #765556;
}

@media (max-width: 1500px) {
  .flavour_content {
    width: 95%;
  }
  .flavour_txt {
    padding: 0 5rem;
  }
}

@media (max-width: 1000px) {
  .flavour_img_container {
    background-attachment: scroll;
  }
  .flavour_content {
    display: block;
    height: auto;
  }
  .flavour_image {
    width: 100%;
    height: 25rem;
  }

  .flavour_txt {
    width: fit-content;
    padding: 2rem 0rem;
    margin: auto;
  }
  .flavour_txt h2 {
    text-align: center;
  }
  .flavour_grid {
    column-gap: 20px;
  }
}
@media (max-width: 600px) {
  .flavour_grid {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0px;
  }
  .flavour_txt {
    text-align: center;
  }
  .flavour_content {
    width: 100%;
    margin: 2rem 0;
  }
}
