@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400..800;1,400..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&display=swap");
* {
  padding: 0;
  border: none;
  outline: none;
  list-style: none;
  box-sizing: border-box;
  border-collapse: inherit;
  font-kerning: none;
  text-rendering: optimizeSpeed;
}
.text_container h1 {
  font-family: "adobe-arabic", sans-serif;
  font-size: 100px;
  font-weight: normal;
  margin: 0;
  padding-top: 30px;
  color: #6a363b;
  line-height: 0.8;
}

.text_container h2 {
  /* font-family: "Red Hat Text", sans-serif; */
  /* font-optical-sizing: auto; */
  font-family: "adobe-arabic", sans-serif;
  font-size: 2.813rem;
  font-weight: 300;
  color: #6a363b;
}
.text_container h3 {
  font-family: "Red Hat Text", sans-serif;
  font-optical-sizing: auto;
  font-size: 1.125rem;
  color: #6a363b;
}

.text_container p {
  letter-spacing: 0.8px;
  font-family: "Red Hat Text", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 1rem;
  color: #6a363b;
  line-height: 24.0854px;
}

/* .celebration_txt_cont {
  margin-top: 50px;
} */

.product_hero_container {
  text-align: center;
  color: #6a363b;
  margin-top: 50px;
}

.product_hero_container h1 {
  /* font-family: "Red Hat Text", sans-serif;
  font-optical-sizing: auto; */
  font-family: "adobe-arabic", sans-serif;
  font-size: 120px;
  font-weight: 300;
  /* color: #6a363b; */
  margin: 0;
  padding-top: 30px;
}

.product_hero_container p {
  letter-spacing: 0.8px;
  line-height: 1.4;
  font-family: "Red Hat Text", sans-serif;
  font-optical-sizing: auto;
  font-size: 1rem;
  /* text-wrap: balance; */
}

#hero_subtitle {
  font-weight: bold;
  font-style: italic;
  font-size: 1rem;
  margin-top: 20px;
  margin-bottom: 30px;
  padding-left: 10%;
  padding-right: 10%;
}

#hero_paragraph {
  font-style: normal;
  font-size: 1rem;
  width: 60%;
  margin: auto;
  text-align: center;
  text-wrap: balance;
}

#hero_paragraph span {
  display: block;
  margin-bottom: 1rem;
}

.hero_btn_link {
  color: #6a363b !important;
  text-decoration: none;
  font-weight: bold;
  padding: 8px 0px;
  width: 200px;
  border: 2px solid #6a363b;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  display: inline-block;
}

.hero_btn_link:hover {
  background-color: #6a363b;
  color: #fff !important;
  cursor: pointer;
}

.pinkBtn {
  background-color: #f5dad1;
  color: black;
  width: 150px;
  height: 50px;
  margin-top: 1rem;
  transition: 0.1s ease-in;
}

.pinkBtn:hover {
  background-color: #f6ece6;
}

.brownBtn {
  background-color: #6a363b;
  width: 170px;
  height: 50px;
  margin-top: 1rem;
  color: white;
  transition: 0.1s ease-in;
}

.brownBtn:hover {
  background-color: #765556;
}

@media (max-width: 1500px) {
  #hero_paragraph {
    width: 80%;
  }
}

@media (max-width: 1200px) {
  #hero_paragraph {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }
  .text_container h1 {
    font-size: 3.5rem; /* Scale down for tablets */
  }

  .text_container h2 {
    font-size: 2.25rem; /* Scale down for tablets */
  }

  .text_container h3 {
    font-size: 1rem; /* Slightly smaller on tablets */
  }

  .text_container p {
    font-size: 0.938rem; /* Slightly smaller for paragraph text */
  }

  .product_hero_container h1 {
    font-size: 3.5rem;
  }

  .product_hero_container p {
    font-size: 0.938rem;
  }

  #hero_subtitle {
    font-size: 0.938rem;
  }

  #hero_paragraph {
    font-size: 0.938rem;
  }
}

/* Media query for very small screens (phones) */
@media (max-width: 480px) {
  .text_container h1 {
    font-size: 2.5rem;
  }

  .text_container h2 {
    font-size: 1.875rem; /* Scale down for mobile phones */
  }

  .text_container h3 {
    font-size: 0.938rem; /* Further reduction for smaller screens */
  }

  .text_container p {
    font-size: 0.875rem; /* Adjust paragraph text size */
  }

  .product_hero_container h1 {
    font-size: 2.5rem;
  }

  .product_hero_container p {
    font-size: 0.875rem;
  }

  #hero_subtitle {
    font-size: 0.875rem;
    text-align: left;
  }

  #hero_paragraph {
    font-size: 0.875rem;
  }
  #hero_paragraph {
    text-align: left;
    font-size: 0.875rem;
  }
}
