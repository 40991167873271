/* Styles for floating button */
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #6a363b;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  z-index: 1000; /* Make sure the button stays above all content */
}

/* Overlay to dim the background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7); /* Transparent white */
  z-index: 999; /* Just below the button and popup */
}

/* Popup container */
.popup {
  position: fixed;
  bottom: 20px; /* Adjust as needed */
  right: 80px; /* Adjust to move the popup to the left of the main button */
  display: flex;
  flex-direction: row; /* Ensure icons are displayed side by side */
  gap: 15px; /* Space between icons */
  z-index: 1000; /* Same as floating button */
}

/* Styles for each icon */
.icon {
  background-color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff5722;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  font-size: 1.5rem;
}

#floating_btn {
  display: none;
}

@media (max-width: 1000px) {
  #floating_btn {
    display: block;
  }
}
