.maintenance-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f8f8;
}

.maintenance-title {
  font-size: 3rem;
  color: #333;
  margin-bottom: 20px;
}

.maintenance-message {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.5;
  max-width: 600px;
}

.maintenance-footer {
  margin-top: 30px;
  font-style: italic;
  color: #999;
}
